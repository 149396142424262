import React from "react";
import { DEFAULT_IMAGE } from "../constant/imageHandler";
import { CardMedia, Card, Button } from "@mui/material";

function BackgroundDiv(props: any) {
  const replaceBackslashes = (str: string): string => {
    return str.replace(/\\/g, "/");
  };
  const styles: any = {
    // display: "flex",
    height: "30rem",
    flexGrow: "1",
    backgroundSize: "cover",
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("${
      props.image ? replaceBackslashes(props.image) : DEFAULT_IMAGE
    }")`,
  };
  // return <div style={styles} />;
  // console.log(props.image)

  return (
    <Card
      elevation={0}
      sx={{ position: "relative", width: "100%", borderRadius: "1rem" }}
    >
      <CardMedia component="img" sx={styles} />
      {/* <img style={styles} /> */}
      <Button
        variant="contained"
        component="label"
        sx={{
          position: "absolute",
          bottom: "8px",
          right: "16px",
          backgroundColor: "white",
          color: "black",
          display: props.view ? "none" : "block",
        }}
      >
        Upload File
        <input
          type="file"
          hidden
          name="coverPhoto"
          onChange={(e: any) => props.updateImage(e)}
        />
      </Button>
    </Card>
  );
}

export default BackgroundDiv;
