import {
  Button,
  CircularProgress,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import withModal from "../HOC/withModal";
import StoreRoundedIcon from "@mui/icons-material/StoreRounded";
import FeedbackSection from "./FeedbackSection";
import { useEffect, useState } from "react";
import FeedbackAPI from "../../utils/APIs/feedbackAPI";
import Discount from "./Discount";
import Redeemed from "./Redeemed";
import DiscountAPI from "../../utils/APIs/discountAPI";
import Cooldown from "./Cooldown";
import Loading from "../Loading/Loading";

function FeedbackButton({
  businessId,
  triggerOpen,
}: {
  businessId?: string;
  triggerOpen: boolean;
}) {
  // const FeedbackButton = withModal(ButtonController, ModalViewFeedbackForm)
  const FeedbackButton = withModal(ButtonController, ModalViewFeedbackForm);
  return <FeedbackButton businessId={businessId} triggerOpen={triggerOpen} />;
}

export default FeedbackButton;

const ButtonController = ({
  openModal,
  triggerOpen,
}: {
  openModal: any;
  triggerOpen: boolean;
}) => {
  useEffect(() => {
    triggerOpen && openModal();
  }, []);
  return (
    <Button
      variant="outlined"
      sx={{ textTransform: "none", fontSize: "0.8rem" }}
      startIcon={<StoreRoundedIcon />}
      onClick={openModal}
    >
      Feedback
    </Button>
  );
};

const ModalViewFeedbackForm = ({ businessId }: { businessId: string }) => {
  const [feedbackForm, setFeedbackForm] = useState<any>([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [redeemed, setRedeemed] = useState(false);
  const [status, setStatus] = useState<any>(null);
  const [checked, setChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleChange = (event: any) => {
    if (event.target.checked === false) {
      //remove the last feedback from the array
      setFeedbackForm((prevFeedback: any) => {
        prevFeedback.pop();
        return prevFeedback;
      });
    }
    setChecked(event.target.checked);
  };

  useEffect(() => {
    const getStatus = async () => {
      setIsLoading(true);
      const response: any = await DiscountAPI.getDiscountStatus();
      setStatus(response.status);
      setIsLoading(false);
    };
    getStatus();
  }, []);

  const updateFeedbackForm = (feedback: any, index?: number) => {
    // add another feedback to the form array
    if (index === undefined || index < 0) {
      return setFeedbackForm((prevFeedback: any) => [
        ...prevFeedback,
        feedback,
      ]);
    } else {
      const tempFeedbackForm: any = [...feedbackForm];
      tempFeedbackForm[index] = { ...feedbackForm[index], ...feedback };
      setFeedbackForm(tempFeedbackForm);
    }
  };
  // console.log(feedbackForm);
  const isValidated = () => {
    for (let i = 0; i < feedbackForm.length; i++) {
      if (
        feedbackForm[i].customerRating === null ||
        feedbackForm[i].customerRating === undefined
      )
        return false;
    }
    return true;
  };
  const updateReemed = () => {
    setRedeemed(true);
  };

  const onSubmitHandler = async () => {
    try {
      const feedback = await FeedbackAPI.createFeedbacks(
        businessId,
        feedbackForm
      );
      // console.log(feedback)
      setIsSubmitted(true);
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </div>
    );

  if (redeemed) return <Redeemed />;

  if (status === "to-be-redeem")
    return <Discount updateReemed={updateReemed} />;

  if (status === "on-cooldown") return <Cooldown cooldownPeriod={2} />;

  return isSubmitted ? (
    <Discount updateReemed={updateReemed} />
  ) : (
    <div>
      <Typography variant="h5" sx={{ fontWeight: "bold", mt: "1rem" }}>
        Please let us know how we can improve your experience.
      </Typography>
      <FeedbackSection
        updateFeedbackForm={updateFeedbackForm}
        index={0}
        ratingValue={feedbackForm[0]?.customerRating || null}
        question={"Customer Service"}
        labels={[
          "Coffee Nightmare",
          "Caffeine Confusion",
          "Decent Pour",
          "Foamy Friendliness",
          "Espresso Excellence",
        ]}
      />
      <FeedbackSection
        updateFeedbackForm={updateFeedbackForm}
        index={1}
        ratingValue={feedbackForm[1]?.customerRating || null}
        question={"Atmoshpere"}
        labels={[
          "Dull and Uninspiring",
          "Ordinary and Plain",
          "Decent and Average",
          "Enjoyable and Pleasant",
          "Exceptional and Enchanting",
        ]}
      />
      <FeedbackSection
        updateFeedbackForm={updateFeedbackForm}
        index={2}
        ratingValue={feedbackForm[2]?.customerRating || null}
        question={"Speed"}
        labels={[
          "Monday morning slow",
          "Taking its time",
          "Relaxed pace",
          "Caffeinated runner",
          "Rapid-fire service",
        ]}
      />
      <Typography variant="h6" sx={{ marginTop: "1rem" }}>
        Would you like to give feedback on a specific menu item?
      </Typography>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>No</Typography>
        <Switch
          checked={checked}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
        />
        <Typography>Yes</Typography>
      </Stack>

      {checked && (
        <FeedbackSection
          updateFeedbackForm={updateFeedbackForm}
          index={3}
          ratingValue={feedbackForm[3]?.customerRating || null}
          question={"Feedback on Menu Item "}
          labels={[
            "Unsatisfactory",
            "Fair",
            "Good",
            "Very Good",
            "Outstanding",
          ]}
          //businessId is for fetching menu items
          businessId={businessId}
        />
      )}

      {/* <FeedbackSection
        updateFeedbackForm={updateFeedbackForm}
        index={2}
        question={"Any other suggestions (optional)"}
        labels={["Unsatisfactory", "Fair", "Good", "Very Good", "Outstanding"]}
      /> */}

      <Button
        variant="outlined"
        sx={{ mb: "2rem", mt: "1rem" }}
        onClick={onSubmitHandler}
        disabled={!isValidated()}
      >
        Submit
      </Button>
    </div>
  );
};
