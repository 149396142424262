import { AUTH, LOGOUT } from "../constants";
import { AnyAction } from "redux";

export default function authReducer(prevState: string = "Saved", action: AnyAction) {
  switch (action.type) {
    case AUTH:
      return " ...prevState, token: action.data ";
    case LOGOUT:
      return "{ ...prevState, token: null }";
    default:
      return prevState;
  }
}
