import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MyBusinessList from "../components/UserProfile/MyBusinessList";
import MyBusinessDetail from "../components/MyBusiness/MyBusinessDetail";
import LikedList from "../components/UserProfile/LikedList";
import FavoritedList from "../components/UserProfile/FavoritedList";

function UserProfile() {
  const user = useSelector((state: any) => state.user);
  const myBusiness = useSelector((state: any) => state.business);
  // console.log(user);
  const [form, setForm] = useState<any>(myBusiness);
  useEffect(() => {
    setForm(myBusiness);
  }, [myBusiness]);

  const updateForm = async (event: any) => {
    if (event.target.name === "coverPhoto" || event.target.name === "logo") {
      return setForm((prevState: any) => ({
        ...prevState,
        [event.target.name]: event.target.files[0],
      }));
    }

    if (
      event.target.name === "address1" ||
      event.target.name === "address2" ||
      event.target.name === "city" ||
      event.target.name === "state" ||
      event.target.name === "zipCode" ||
      event.target.name === "country"
    ) {
      return setForm((prevState: any) => ({
        ...prevState,
        location: {
          ...prevState.location,
          [event.target.name]: event.target.value,
        },
      }));
    }

    return setForm((prevState: any) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  return (
    <div>
      {form?._id ? (
        <MyBusinessDetail form={form} updateForm={updateForm} view={false} openFeedbackModal={false} />
      ) : (
        <div>
          {user.businessesOwn?.length > 0 && (
            <MyBusinessList businessesList={user.businessesOwn} />
          )}
          <h1>Favorited Businesses</h1>
          {user.favorites?.length > 0 ? (
            <FavoritedList favoritesList={user.favorites} />
          ) : (
            <div>No favorited business found</div>
          )}

          <h1>Liked Items</h1>
          {user.likedItems?.length > 0 ? (
            <LikedList likedList={user.likedItems} />
          ) : (
            <div>No liked item found</div>
          )}
        </div>
      )}
    </div>
  );
}

export default UserProfile;
