import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Typography } from '@mui/material';

function Redeemed() {
    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <CheckCircleIcon sx={{ fontSize: 100, color: "success.main" }} />
            <Typography variant="h5" sx={{ fontWeight: "bold", mt: "1rem" }}>
                Successfuly redeemed!
            </Typography>
        </div>
    )
}

export default Redeemed