import React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Divider } from "@mui/material";

type SearchBarProps = {
  mobileChecker: boolean;
};

export default function SearchBar({ mobileChecker }: SearchBarProps) {
  // const dispatch = useDispatch<any>();
  const [input, setInput] = React.useState<string>("");
  const navigate = useNavigate();
  const onChangeHandler = (e: any) => {
    // console.log(e.target.value);
    setInput(e.target.value);
  };

  const handleSearchSubmit = async (e: any) => {
    e.preventDefault();
    if (input === "") return alert("Please enter something to search");
    navigate(`/search?input=${input}`);
  };
  return (
    <Paper
      component="form"
      onSubmit={handleSearchSubmit}
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: mobileChecker === true ? "90%" : "400px",
        boxShadow: "none",
        border: "1px solid #E6E7E9",
        borderRadius: "10px",
      }}
    >
      <IconButton sx={{ p: "10px" }} aria-label="menu"></IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="search for item, service, or business"
        fullWidth={true}
        // inputProps={{ "aria-label": "search google maps" }}
        onChange={onChangeHandler}
        value={input}
      />
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton
        type="button"
        sx={{ p: "10px" }}
        aria-label="search"
        onClick={handleSearchSubmit}
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}
