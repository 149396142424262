import React from 'react'
import { CircularProgress } from '@mui/material'

function Loading() {
    return (
        <div style={{ display: "flex", height: "80vh", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress />
        </div>
    )
}

export default Loading