import axios from "./interceptors";
const OpenAIAPI = {
  fetchAIImage: async (prompt: any) => {
    try {
      const response = await axios.post("/openai/image", { prompt });
      return response.data.photo;
    } catch (error) {
      console.log(error);
    }
  },

  fetchItemDescription: async (prompt: any) => {
    try {
      const response = await axios.post("/openai/description", { prompt });
      return response.data.description;
    } catch (error) {
      console.log(error);
    }
  },
};
export default OpenAIAPI;
