import React from "react";
import CardItem from "../TheList/CardItem";
import { Grid } from "@mui/material";

function LikedList({ likedList }: { likedList: any }) {
  return (
    <Grid container spacing={2}>
      {likedList.map((item: any) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={item._id}>
          <CardItem item={item} />
        </Grid>
      ))}
    </Grid>
  );
}

export default LikedList;
