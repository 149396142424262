
import React from 'react'
import { TextField as MuiTextField } from '@mui/material';
import "./style.css";


function TextField(props: any) {
    const styles: any = {
        h1: {
            display: "flex",
            marginTop: 10,
            marginBottom: 10,
            border: "none",
            fontSize: 50,
            width: "100%",
            fontWeight: "bold",
        },
        h2: {
            display: "flex",
            marginTop: 10,
            marginBottom: 10,
            border: "none",
            fontSize: 30,
            width: "100%",
            fontWeight: "bold",
        },
        p1: {
            margin: 0,
            border: "none",
            // marginTop: 5,
            // marginBottom: 5,
            fontSize: 20
        },
        default: {
            margin: 10,
            border: "none",
            fontSize: 20
        }
    }
    const onChangeHandler = (e: any) => {
        props.updateForm(e)
    }
    return (
        <>
            {props.view || props.view === undefined ?
                <div
                    // className={`${props.type}`}
                    style={styles[props.type] ? styles[props.type] : styles["default"]}
                >{props.value}</div>
                :
                <input
                    className={`${props.type}`}
                    // style={styles[props.type] ? styles[props.type] : styles["default"]}
                    type="text"
                    name={props.name}
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={onChangeHandler}
                />
                // <MuiTextField
                //     sx={{ width: "fit-content" }} // Set width to fit content
                //     // InputProps={{
                //     //     style: { fontSize: 20, width: "fit-content" }, // Set font size of input text
                //     // }}
                //     onChange={onChangeHandler}
                //     name={props.name}
                //     placeholder={props.placeholder}
                //     value={props.value} />
            }
        </>

    )
}
export default TextField

