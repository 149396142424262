import axios from "./interceptors";

const FeedbackAPI = {
  createFeedbacks: async (businessId: string, feedbackForm: any) => {
    try {
      const { data } = await axios.post(`/feedback/${businessId}`, {
        userId: localStorage.getItem("uniqueId"),
        userFeedbacks: feedbackForm,
      });
      return data;
    } catch (error: any) {
      if (error.response) {
        return error.response;
      }
      return error;
    }
  },
};

export default FeedbackAPI;
