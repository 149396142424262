import { createAction } from "../store";
import {
  CREATE_NEW_BUSINESS,
  FETCH_BUSINESS_BY_PHONE,
  UPDATE_BUSINESS,
  DELETE_ITEM,
  CLEAR_BUSINESS,
  CREATE_NEW_ITEM,
  UPDATE_ITEM,
} from "../constants";
import BusinessAPI from "../../utils/APIs/businessAPI";
import axios from "../../utils/APIs/interceptors";

export const getBusinesByPhone = (phone: string) => async (dispatch: any) => {
  const response = await BusinessAPI.fetchBusinessByPhone(phone);
  if (response.data) return alert(response.data.message);
  if (response[0]) dispatch(createAction(FETCH_BUSINESS_BY_PHONE, response[0]));
};

export const updateBusiness =
  (businessForm: any, businessId: string) => async (dispatch: any) => {
    const data = await BusinessAPI.updateBusiness(businessForm, businessId);
    //delay 1 second so that s3 can upload the image
    //not a good solution, but it works for now
    await new Promise((resolve) => setTimeout(resolve, 1000));

    dispatch(createAction(UPDATE_BUSINESS, data.business));
  };

export const deleteItem =
  (itemId: string, businessId: string) => async (dispatch: any) => {
    await axios.delete(`/items/${itemId}`);
    const { data } = await axios.get(`/businesses/${businessId}`);
    dispatch(createAction(DELETE_ITEM, data.business));
  };

export const createNewBusiness =
  (businessForm: any) => async (dispatch: any) => {
    // const { data } = await axios.post("/businesses/", businessForm);
    const response = await BusinessAPI.createBusiness(businessForm);
    if (response.data) return alert(response.data.message);

    dispatch(createAction(CREATE_NEW_BUSINESS, response.business));
  };

export const clearBusiness = () => (dispatch: any) => {
  dispatch(createAction(CLEAR_BUSINESS, {}));
};

export const createNewItem =
  (itemForm: any, businessId: string) => async (dispatch: any) => {
    await axios.post(`/items/${businessId}`, itemForm);
    const { data } = await axios.get(`/businesses/${businessId}`);
    dispatch(createAction(CREATE_NEW_ITEM, data.business));
  };

export const updateItem =
  (itemForm: any, itemId: string) => async (dispatch: any) => {
    await axios.put(`/items/${itemId}`, itemForm);
    const { data } = await axios.get(
      `/businesses/${itemForm.get("businessId")}`
    );
    dispatch(createAction(UPDATE_ITEM, data.business));
  };
