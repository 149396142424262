
import { useEffect, useState } from "react"
import BusinessAPI from "../../utils/APIs/businessAPI"
import ItemAPI from "../../utils/APIs/itemAPI";


function useFetchByPage(pageNumber: number, collectionType: string) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [result, setResult] = useState<any>([]);
    const [hasMore, setHasMore] = useState(false);

    useEffect(() => {
        const fetchAPI = async () => {
            try {
                setLoading(true)
                setError(false)
                let response: any
                if (collectionType === "business") {
                    response = await BusinessAPI.fetchAllBusinessesByPage(pageNumber)
                }
                //collection type item or other
                else {
                    response = await ItemAPI.fetchItemsByPage(pageNumber)
                }
                // console.log(response)
                setResult((prev: any) => {
                    return [...prev, ...response.data]
                })
                setHasMore(response.data.length > 0)
                setLoading(false)

            } catch (error) {
                setError(true)
            }

        }
        fetchAPI()

    }, [pageNumber])
    return { result, hasMore, loading, error }
}

export default useFetchByPage