export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const SEARCH = "SEARCH";

export const CREATE_NEW_BUSINESS = "CREATE_NEW_BUSINESS";
export const FETCH_ALL_BUSINESSES = "FETCH_ALL_BUSINESSES";
export const FETCH_BUSINESS_BY_PHONE = "FETCH_BUSINESS_BY_PHONE";
export const UPDATE_BUSINESS = "UPDATE_BUSINESS";
export const CLEAR_BUSINESS = "CLEAR_BUSINESS";

export const DELETE_ITEM = "DELETE_ITEM";
export const CREATE_NEW_ITEM = "CREATE_NEW_ITEM";
export const UPDATE_ITEM = "UPDATE_ITEM";

export const FETCH_USER_PROFILE = "FETCH_USER_PROFILE";
