import { Autocomplete, Chip, TextField, Typography } from "@mui/material";
import React, { useState } from "react";

function TagsInput({
  tags,
  handleTagChange,
}: {
  tags: string[];
  handleTagChange: any;
}) {
  const MAX_TAGS = 5;
  const [limit, setLimit] = useState(false); // State to check if the number of selected tags is within the limit
  const handleTagChangeWithLimit = (event: any, newValue: any) => {
    // Check if the number of selected tags is within the limit
    if (newValue.length <= MAX_TAGS) {
      setLimit(false); // Set the limit state to false to hide the error message
      handleTagChange(event, newValue); // Call the provided handleTagChange function to update the state
    } else {
      setLimit(true); // Set the limit state to true to show the error message
    }
  };
  return (
    <>
      <Autocomplete
        multiple
        fullWidth
        id="tags-filled"
        options={tagsList.map((option) => option.title)}
        // defaultValue={[top100Films[13].title]}
        freeSolo
        value={tags}
        onChange={handleTagChangeWithLimit}
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Tags (5 Max)"
            placeholder="click Enter after each tag"
          />
        )}
      />
      {limit && (
        <Typography variant="caption" color="textSecondary">
          You have reached the maximum number of tags
        </Typography>
      )}
    </>
  );
}

export default TagsInput;
const tagsList = [
  { title: "Vietnamese" },
  { title: "Pho" },
  { title: "Bún Bò" },
  { title: "Cơm" },
  { title: "Advs" },
];
