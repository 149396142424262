import * as React from 'react';
import { IMaskInput } from 'react-imask';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(
    function TextMaskCustom(props, ref) {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask="(#00) 000-0000"
                definitions={{
                    '#': /[1-9]/,
                }}
                // inputRef={ref}
                onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        );
    },
);




export default function PhoneInput({ value, setValue }: any) {
    // const [value, setValue] = React.useState<string>("");

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value)
    };
    // console.log(value)
    return (
        <Box>
            <FormControl sx={{ width: "100%" }}>
                <InputLabel htmlFor="formatted-text-mask-input">Number Input</InputLabel>
                <Input
                    value={value}
                    onChange={handleChange}
                    id="formatted-text-mask-input"
                    inputComponent={TextMaskCustom as any}
                />
            </FormControl>

        </Box >
    );
}