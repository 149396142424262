export const objectIsEmpty = (obj: any) => {
    return JSON.stringify(obj) === '{}'
}

export const fileToBase64 = async (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader.result as string);
        };
        reader.onerror = () => {
            reject(null);
        };
    });
}