import React, { useState, useEffect } from "react";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import LinearWithValueLabel from "./LinearWithValueLabel";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, IconButton, Modal } from "@mui/material";
import ItemAPI from "../../utils/APIs/itemAPI";
import { useSelector, useDispatch } from "react-redux";
import { updateUserProfile } from "../../redux/actions/userAction";
import { objectIsEmpty } from "../../utils/shared/script";
import Auth from "../Auth/Auth";
import { outline, signinContainer } from "../Auth/styles";

function LikeDislikeButtons({
  popularity,
  itemId,
  view,
}: {
  popularity: any;
  itemId: string;
  view: boolean | undefined;
}) {
  const [open, setOpen] = useState(false);
  const [like, setLike] = useState(false);
  const [dislike, setDislike] = useState(false);
  const [popularityState, setPopularityState] = useState(popularity);
  const userProfile = useSelector((state: any) => state.user);
  const dispatch = useDispatch<any>();
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // console.log(userProfile)
  useEffect(() => {
    const run = async () => {
      // console.log(itemId)
      if (itemId) {
        const likedList = userProfile.likedItems
          ? userProfile.likedItems.map((item: any) => item._id)
          : [];
        const dislikedList = userProfile.dislikedItems
          ? userProfile.dislikedItems.map((item: any) => item._id)
          : [];
        setLike(likedList.includes(itemId));
        setDislike(dislikedList.includes(itemId));
        const { item } = await ItemAPI.fetchItemDetail(itemId);
        if (item) setPopularityState(item.popularity);
      }
    };
    run();
  }, [userProfile]);

  // console.log(userProfile)

  const handleLike = async () => {
    if (objectIsEmpty(userProfile)) {
      return handleOpen();
    }
    let response;
    if (itemId) {
      if (!like) {
        //like not clicked
        setLoading(true);
        response = await ItemAPI.likeItem(itemId);
        setLoading(false);
      } else {
        //like clicked
        setLoading(true);
        response = await ItemAPI.unlikeItem(itemId);
        setLoading(false);
      }
      setPopularityState(
        response ? response.data.popularity : { likes: 0, dislikes: 0 }
      );

      //get the latest user profile
      dispatch(updateUserProfile());
      setLike((prev) => !prev);
      setDislike(false);
    }
  };

  return (
    <div
      style={{
        display: view ? "flex" : "none",
        alignItems: "center",
        // flexDirection: "column",
        // width: "100%",
      }}
    >
      {like ? (
        <Button
          variant="outlined"
          onClick={handleLike}
          disabled={!loading ? false : true}
          sx={{ textTransform: "none" }}
          startIcon={<ThumbUpIcon />}
        >
          {popularityState.likes}
          {/* {popularityState.likes > 1 ? " votes" : " vote"} */}
        </Button>
      ) : (
        <Button
          variant="outlined"
          onClick={handleLike}
          startIcon={<ThumbUpOutlinedIcon />}
          disabled={!loading ? false : true}
          sx={{ textTransform: "none" }}
        >
          {popularityState.likes}
          {/* {popularityState.likes > 1 ? " votes" : " vote"} */}
        </Button>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={outline}>
          <IconButton
            aria-label="close"
            // style={{ float: "right" }}
            onClick={handleClose}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
          <Box sx={signinContainer}>
            <Auth title="Please Sign In to 👍" />
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default LikeDislikeButtons;
