import { combineReducers, AnyAction } from "redux";
import myBusinessReducer from "./myBusinessReducer";
import authReducer from "./authReducer";
import userReducer from "./userReducer";
export default function rootReducer(prevState: any, action: AnyAction) {
  const reducers = combineReducers({
    auth: authReducer,
    business: myBusinessReducer,
    user: userReducer,
  });
  return reducers(prevState, action);
}
