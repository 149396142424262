import { Button, Chip, Container } from '@mui/material'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import withModal from '../HOC/withModal'

function CategoryList() {
    // Trending
    const data = ["Coffee", "Banh Mi", "Beef", "Pork", "Sushi", "Thai", "Vietnamese", "Ramen",
        "Vegan", "Hotpot", "Seafood", "Pho", "Pizza", "Burger", "Taco", "Sushi Burrito",
        "Pasta", "Ice Cream", "Curry", "Salad", "Steak", "Noodles", "Dumplings", "Fried Chicken",
        "Smoothie", "Sandwich", "Tofu", "Fries", "Burrito", "Falafel", "Pancakes", "Waffles"]
    const navigate = useNavigate()

    const SeeMoreButton = withModal(ButtonController, ModalViewSeeMore)

    const [width, setWidth] = React.useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])


    const onClickHandler = (item: any) => {
        navigate(`/search?input=${item}`);
    }
    const slicedData = width > 600 ? data.slice(0, 12) : data.slice(0, 7)
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center", // Center items horizontally
                flexWrap: "wrap", // Wrap items to the next row
                // gap: "2rem", // Add spacing between items
            }}
        >
            {slicedData.map((item) => (
                <div key={item} style={{ flex: "1 0 auto", maxWidth: "200px", margin: ".5rem" }}>
                    <Chip
                        label={item}
                        variant="outlined"
                        sx={{ width: "100%" }}
                        onClick={() => onClickHandler(item)}
                    />
                </div>
            ))}
            <SeeMoreButton tags={data} />
        </div>
    )

}

export default CategoryList

const ButtonController = ({ openModal }: any) => {
    return <Button
        variant='outlined'
        sx={{
            marginLeft: "3rem",
            textTransform: "none",
        }}
        onClick={openModal}>See more</Button>
}
const ModalViewSeeMore = ({ tags }: { tags: any }) => {
    const navigate = useNavigate()
    const onClickHandler = (item: any) => {
        navigate(`/search?input=${item}`);
    }

    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center", // Center items horizontally
            flexWrap: "wrap", // Wrap items to the next row
            // gap: "1rem", // Add spacing between items
        }}>

            {tags.map((tag: any) => (
                <div key={tag} style={{ margin: ".5rem" }}>
                    <Chip label={tag}
                        variant="outlined"
                        sx={{ width: "100%" }}
                        key={tag}
                        onClick={() => onClickHandler(tag)}
                    />
                </div>

            ))}
        </div>



    )
}