import { CardMedia } from "@mui/material";
import { Stack } from "@mui/system";
import React, { FC, useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { DEFAULT_IMAGE } from "../../../utils/constant/imageHandler";
import BackgroundDiv from "../../../utils/shared/BackgroundDiv";
import { fileToBase64 } from "../../../utils/shared/script";

function CoverImage({
  hasCover,
  hasLogo,
  updateForm,
  form,
  view,
}: {
  hasCover: boolean;
  hasLogo: boolean;
  updateForm: any;
  form: any;
  view: boolean;
}) {
  const [localImage, setLocalImage] = useState<any>(DEFAULT_IMAGE);
  const [localLogo, setLocalLogo] = useState<any>(DEFAULT_IMAGE);

  const convertImage = async (image: any) => {
    if (!image) return DEFAULT_IMAGE;
    if (typeof image === "string" && !image.startsWith("http"))
      return `${process.env.REACT_APP_API}/${image}`;

    if (typeof image === "string" && image.startsWith("http")) return image;
    return await fileToBase64(image);
  };
  // console.log({ localImage, localLogo });
  useEffect(() => {
    (async () => {
      const image = await convertImage(form.coverPhoto);
      setLocalImage(image);
      const logo = await convertImage(form.logo);
      setLocalLogo(logo);
    })();
  }, [form.coverPhoto, form.logo]);
  return (
    <Stack>
      {hasCover && (
        <div style={{ display: "flex" }}>
          {/* {businessDetails.images.map((image: string) => <BackgroundDiv image={image} />)} */}
          <BackgroundDiv
            image={localImage}
            updateImage={updateForm}
            view={view}
          />
        </div>
      )}
      {hasLogo && (
        <>
          <CardMedia
            src={localLogo ? localLogo : DEFAULT_IMAGE}
            component="img"
            sx={{
              position: "relative",
              top: hasCover ? -50 : 0,
              left: hasCover ? 20 : 0,
              height: "100px",
              width: "100px",
              // borderRadius: "50%",
              borderRadius: "1rem",
            }}
          ></CardMedia>
          <input
            style={{
              position: "relative",
              top: hasCover ? -35 : 0,
              display: view ? "none" : "block",
              // backgroundColor: "black",
              width: "fit-content",
            }}
            type="file"
            onChange={updateForm}
            name="logo"
          />
        </>
      )}
    </Stack>
  );
}

export default CoverImage;
