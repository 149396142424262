import { Box, Grid, Stack } from "@mui/material";
import Loading from "../../Loading/Loading";
import AddItemButton from "../AddItemButton/AddItemButton";
import TextField from "../TextField/TextField";
import Item from "./Item";

const MenuList = ({
  menu,
  view,
  topic,
}: {
  menu: any;
  view: boolean | undefined;
  topic: string;
}) => {
  // console.log(menu);
  if (!menu) return <Loading />;
  return (
    <Box sx={{ flexGrow: 1 }}>
      {menu.length > 0 && <TextField value={topic} type="h2" view={true} />}
      <Stack
        direction="row"
        spacing={2}
        sx={{
          width: "100%",
          padding: "0.5rem",
          overflowX: "scroll",
          overflowY: "hidden",
          backgroundColor: "#fff",
        }}
      >
        {menu.sort((a: any, b: any) => b.popularity.likes - a.popularity.likes)
          .map((item: any) =>
            <Item item={item} view={view} key={item._id} />
          )}
        {menu.length > 0 && <AddItemButton topic={topic} view={view} />}
      </Stack>
    </Box >
  );
};
export default MenuList;
