import { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Modal,
  Typography,
} from "@mui/material";
import DiscountAPI from "../../utils/APIs/discountAPI";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: 4,
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

function Discount({ updateReemed }: { updateReemed: any }) {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const handleClicked = async () => {
    await DiscountAPI.redeemDiscount();
    updateReemed();
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Typography variant="h5" sx={{ fontWeight: "bold", mt: "1rem" }}>
        Thank you!
      </Typography>
      <Typography variant="h5">
        Your feedback is very valuable to us.
      </Typography>
      <Card sx={{ maxWidth: 345, mt: "1rem" }}>
        <CardMedia
          component="img"
          height="140"
          image="https://www.omnisend.com/blog/wp-content/uploads/2022/10/10-12-Discount-pricing-strategies.png"
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            10% Discount on your next order (Drip & Sip)
          </Typography>
          <Typography variant="body2" color="text.secondary">
            You can close this window now, scan the QR code again next time to redeem.
          </Typography>
          <Typography variant="body2" color="error">
            Please, show this to the cashier to redeem
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" color="primary" onClick={handleOpen}>
            Redeem
          </Button>
        </CardActions>
      </Card>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 200 }}>
          <h2 id="child-modal-title">Redeem discount (10%)</h2>
          <p id="child-modal-description">
            Only click YES after you show this to the cashier
          </p>
          <Button onClick={handleClicked}>Yes</Button>
        </Box>
      </Modal>
    </div>
  );
}

export default Discount;
