import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./App.css";
// import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import BusinessDetail from "./components/Businesses/BusinessDetail/BusinessDetail";
import BusinessesList from "./pages/BusinessesList";
import NavBar from "./components/NavBar/NavBar";
// import Saved from "./pages/Saved";
import MyBusiness from "./pages/MyBusiness";
import { Container, Divider } from "@mui/material";
import TheList from "./pages/TheList";
import { updateUserProfile } from "./redux/actions/userAction";
import UserProfile from "./pages/UserProfile";
import { objectIsEmpty } from "./utils/shared/script";
import Search from "./pages/Search";

function App() {
  const dispatch = useDispatch<any>();
  useEffect(() => {
    dispatch(updateUserProfile());
  }, []);

  const user = useSelector((state: any) => state.user);
  return (
    <Router>
      <NavBar userProfile={user} />
      <Container maxWidth="xl" sx={{ marginTop: "1rem" }}>
        <Routes>
          <Route path="*" element={<p>Page Not Found!</p>} />
          <Route path="/" element={<Navigate to={"/the-list"} />} />
          <Route path="/businesses" element={<BusinessesList />} />
          <Route path="/search" element={<Search />} />
          <Route
            path="/my-business"
            element={
              user?.role === "admin" ? (
                <MyBusiness />
              ) : (
                <p>Please Login As Admin to Access</p>
              )
            }
          />
          <Route path="/the-list" element={<TheList />} />
          <Route
            path="/profile"
            element={
              objectIsEmpty(user) ? <p>Please Login </p> : <UserProfile />
            }
          />
          {/* <Route
            path="/saved"
            element={user ? <Saved /> : <Navigate to="/businesses" />}
          /> */}
          <Route path="/businesses/:id" element={<BusinessDetail openFeedbackModal={false} />} />
          <Route path="/businesses/:id/feedback" element={<BusinessDetail openFeedbackModal={true} />} />
        </Routes>
      </Container>

      {/* GAP so that footer not going to cover the content */}
      <div
        style={{
          // backgroundColor: "black",
          height: 80,
        }}
      />
      <Footer />
    </Router>
  );
}

export default App;

const Footer = () => {
  return (
    <footer
      style={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        textAlign: "center",
        backgroundColor: "white",
      }}
    >
      <Divider />
      <p>vninus2023@gmail.com</p>
    </footer>
  );
};
