import axios from "axios";

const API = axios.create({
  timeout: 50000,
  baseURL: process.env.REACT_APP_API,
});

API.interceptors.request.use(
  (config) => {
    if (localStorage.getItem("token") && config.headers) {
      config.headers["Authorization"] = localStorage.getItem("token");
    }
    return config;
  },
  (e) => {
    console.log("Request Error: ", e);
    return Promise.reject(e);
  }
);

API.interceptors.response.use(
  (res) => {
    // Status 2xx
    return res;
  },
  (e) => {
    // Status not 2xx
    // Handle individual status codes
    // If unauthorized, send another request for a refresh token

    // console.log("Response Error: ", e);
    return Promise.reject(e);
  }
);

export default API;
