import { AnyAction } from "redux";
import {
  CREATE_NEW_BUSINESS,
  FETCH_BUSINESS_BY_PHONE,
  UPDATE_BUSINESS,
  DELETE_ITEM,
  CLEAR_BUSINESS,
  CREATE_NEW_ITEM,
  UPDATE_ITEM,
} from "../constants";

export default function myBusinessReducer(
  prevState: any = {},
  action: AnyAction
) {
  switch (action.type) {
    case FETCH_BUSINESS_BY_PHONE:
      return action.payload;

    case UPDATE_BUSINESS:
      return action.payload;

    case DELETE_ITEM:
      return action.payload;

    case CREATE_NEW_BUSINESS:
      return action.payload;

    case CREATE_NEW_ITEM:
      return action.payload;

    case UPDATE_ITEM:
      return action.payload;

    case CLEAR_BUSINESS:
      return {};

    default:
      return prevState;
  }
}
