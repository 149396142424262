import React from "react";

import { Card, CardActionArea, CardContent, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { getBusinesByPhone } from "../../redux/actions/myBusinessAction";

function BusinessCard({
  name,
  phone,
  displayPhone,
}: {
  name: string;
  phone: string;
  displayPhone: string;
}) {
  const dispatch = useDispatch<any>();

  const onClickHandler = () => {
    dispatch(getBusinesByPhone(phone));
  };
  return (
    <Card>
      <CardActionArea onClick={onClickHandler}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {displayPhone}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default BusinessCard;
