import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  Container,
  Button,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  Link,
  Divider,
} from "@mui/material";
import SearchBar from "./SearchBar";
import SignInButton from "../Auth/SignInButton";
import { useNavigate } from "react-router-dom";
import { signOut } from "../../redux/actions/authAction";
import { useDispatch } from "react-redux";
import logo from "./vninus-logo.jpg";
import small_logo from "./vninus-logo-only.jpg";
import { objectIsEmpty } from "../../utils/shared/script";
import MenuIcon from "@mui/icons-material/Menu";

const pages = ["The List", "Businesses", "My Business"];
const pagesRoute = ["the-list", "businesses", "my-business"];
const settings = ["Profile", "Logout"];
const notAvailable = ["My Business"];
const mobileChecker = true;
// const user = JSON.parse(localStorage.getItem("token") || "null");

export default function NavBar({ userProfile }: { userProfile: any }) {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  // console.log(windowWidth);
  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleNavClick = (page: string) => {
    navigate(`/${page}`);
  };

  const handleCloseUserMenu = async (event: any) => {
    const setting = event.currentTarget.id;
    if (setting === "Logout") {
      dispatch(signOut());
    }
    if (setting === "Profile") {
      navigate("/profile");
    }
    setAnchorElUser(null);
  };

  return (
    <div>
      <AppBar
        position="static"
        //Remove Shadow
        elevation={0}
        sx={{ background: "white" }}
      >
        <Container maxWidth="xl">
          <Toolbar sx={{ justifyContent: "space-between", gap: 2 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Link href="/">
                <img
                  src={logo}
                  alt="logo"
                  width="70"
                  style={{
                    // borderRadius: "1rem",
                    // borderRadius: "50%",
                    display: "flex",
                    height: "30px",
                    width: "auto",
                    objectFit: "contain",
                  }}
                />
              </Link>
            </Box>

            {windowWidth > 700 && <SearchBar mobileChecker={!mobileChecker} />}

            <div style={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                {pages.map((page: string, index: number) => {
                  return (
                    <Button
                      key={page}
                      variant="outlined"
                      onClick={() => handleNavClick(pagesRoute[index])}
                      sx={{
                        color: "black",
                        textTransform: "none",
                        borderRadius: "10px",
                        margin: "0 5px",
                        border: "1px solid #E6E7E9",
                        display:
                          //some pages are not available for non-admin users
                          notAvailable.includes(page) &&
                          userProfile?.role !== "admin"
                            ? "none"
                            : "block",
                      }}
                    >
                      {page}
                    </Button>
                  );
                })}
              </Box>
              {objectIsEmpty(userProfile) && <SignInButton />}
              {!objectIsEmpty(userProfile) && (
                <Box sx={{ flexGrow: 0 }}>
                  <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <Avatar alt="Remy Sharp" />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {settings.map((setting) => (
                      <MenuItem
                        key={setting}
                        id={setting}
                        onClick={handleCloseUserMenu}
                      >
                        <Typography textAlign="center">{setting}</Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              )}
              <HamburgerButton
                pages={pages}
                pagesRoute={pagesRoute}
                userProfile={userProfile}
              />
            </div>
          </Toolbar>
        </Container>
        {windowWidth < 700 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "10px",
            }}
          >
            <SearchBar mobileChecker={mobileChecker} />
          </div>
        )}
        <Divider />
      </AppBar>
    </div>
  );
}

const HamburgerButton = ({
  pages,
  pagesRoute,
  userProfile,
}: {
  pages: string[];
  pagesRoute: string[];
  userProfile: any;
}) => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const navigate = useNavigate();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (event: any) => {
    // console.log(event.currentTarget.id);
    if (event.currentTarget.id) {
      navigate(`/${event.currentTarget.id}`);
    }
    setAnchorElNav(null);
  };

  return (
    <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: { xs: "block", md: "none" },
        }}
      >
        {pages.map((page, index) => (
          <MenuItem
            key={page}
            id={pagesRoute[index]}
            onClick={handleCloseNavMenu}
            sx={{
              display:
                //some pages are not available for non-admin users
                notAvailable.includes(page) && userProfile?.role !== "admin"
                  ? "none"
                  : "block",
            }}
          >
            <Typography textAlign="center">{page}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
