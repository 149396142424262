import { Grid } from "@mui/material";
import React from "react";
import BusinessCard from "../Businesses/BusinessesList/BusinessCard";

function FavoritedList({ favoritesList }: { favoritesList: any }) {
  return (
    <Grid container spacing={2}>
      {favoritesList.map((business: any) => (
        <Grid key={business._id} item xs={12} sm={6} md={4} lg={3}>
          <BusinessCard business={business} />
        </Grid>
      ))}
    </Grid>
  );
}

export default FavoritedList;
