import { createAction } from "../store";
import { FETCH_USER_PROFILE } from "../constants";
import AuthAPI from "../../utils/APIs/authAPI";
import { signOut } from "./authAction";
export const updateUserProfile = () => async (dispatch: any) => {
  try {
    const { data } = await AuthAPI.fetchUserProfile();
    dispatch(createAction(FETCH_USER_PROFILE, data.profile));
  } catch (error: any) {
    // console.log(error);
    if (error.response && error.response.data.error === "jwt expired") {
      dispatch(signOut());
    }
  }
};
