import axios from "./interceptors";
const AuthAPI = {
  signIn: async (formData: any) => await axios.post("/user/login", formData),

  signUp: async (formData: any) => await axios.post("/user/signup", formData),

  fetchUserProfile: async () => await axios.get("/user/profile"),

  googleSignIn: async (formData: any) =>
    await axios.post("/user/login/google", formData),
};

export default AuthAPI;
