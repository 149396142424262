import React from 'react'
import { Paper, TextField, Typography } from "@mui/material";

function DescriptionTextField({ form, updateForm, view }: { form: any, updateForm: any, view: boolean | undefined }) {
    // console.log(view)
    return (
        <>
            {
                view ?
                    form.description && <Paper elevation={0}
                        sx={{ border: "1px solid #000", width: "fit-content" }}>
                        <Typography
                            // line break for description view
                            sx={{ whiteSpace: "pre-line", padding: "1rem" }}
                            variant="body1"
                            component="h2"
                        >
                            {form.description}
                        </Typography>
                    </Paper>

                    :
                    <TextField
                        name="description"
                        variant="outlined"
                        label="Description"
                        // margin="normal"
                        multiline
                        minRows={4}
                        // fullWidth
                        value={form.description}
                        onChange={(e) => updateForm(e)}
                    />
            }
        </>
    )
}

export default DescriptionTextField