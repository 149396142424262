import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getBusinesByPhone } from "../redux/actions/myBusinessAction";
import MyBusinessDetail from "../components/MyBusiness/MyBusinessDetail";
import PhoneInput from "../components/PhoneInput";
import CreateBusinessButton from "../components/MyBusiness/CreateBusinessButton/CreateBusinessButton";
import AssignBusiness from "../components/MyBusiness/AssignBusiness";

function MyBusiness() {
  const [phone, setPhone] = useState("");
  const dispatch = useDispatch<any>();
  const myBusiness = useSelector((state: any) => state.business);
  const [form, setForm] = useState<any>(myBusiness);

  const updateForm = async (event: any) => {
    if (event.target.name === "coverPhoto" || event.target.name === "logo") {
      return setForm((prevState: any) => ({
        ...prevState,
        [event.target.name]: event.target.files[0],
      }));
    }

    if (
      event.target.name === "address1" ||
      event.target.name === "address2" ||
      event.target.name === "city" ||
      event.target.name === "state" ||
      event.target.name === "zipCode" ||
      event.target.name === "country"
    ) {
      return setForm((prevState: any) => ({
        ...prevState,
        location: {
          ...prevState.location,
          [event.target.name]: event.target.value,
        },
      }));
    }

    return setForm((prevState: any) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  useEffect(() => {
    setForm(myBusiness);
  }, [myBusiness]);

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (!phone) return alert("Please enter a phone number");
    try {
      dispatch(getBusinesByPhone(phone));
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(menu)
  return (
    <div>
      {form?._id ? (
        <MyBusinessDetail form={form} updateForm={updateForm} view={false} openFeedbackModal={false} />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "80vh",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <AssignBusiness />
          <h1>Phone Number Look up</h1>
          <PhoneInput value={phone} setValue={setPhone} />
          <Button onClick={onSubmit} variant="outlined">
            Search
          </Button>
          <CreateBusinessButton setForm={setForm} phone={phone} />
        </div>
      )}
    </div>
  );
}

export default MyBusiness;
