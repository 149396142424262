import Grid from "@mui/material/Grid";
import { useCallback, useRef, useState } from "react";
import Loading from "../components/Loading/Loading";
import CardItem from "../components/TheList/CardItem";
// import TopFive from "../components/TheList/TopFive";
// import ItemAPI from "../utils/APIs/itemAPI";
// import Categories from "../components/TheList/Categories";
import useFetchByPage from "../components/hooks/useBusinessSearch";
// import CategoriesCarousel from "../components/CategoriesList/CategoriesCarousel";
import CategoryList from "../components/CategoriesList/CategoryList";

function TheList() {
  const [pageNumber, setPageNumber] = useState(1);
  const { result, hasMore, loading, error } = useFetchByPage(pageNumber, "item");
  const observer = useRef<any>();
  const lastItemElementRef = useCallback((node: any) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setPageNumber((prevPageNumber) => prevPageNumber + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, hasMore]);



  if (!result) return <Loading />;
  return (
    <div>
      {/* <Categories /> */}
      {/* <h1>Result of the selected above</h1> */}
      {/* <h1>
        Must Try Vietnamese Food (most liked item, in each categories, or maybe
        tags with the largest occurences number)
      </h1> */}
      {/* <h1>Hangover Recommended, Fall/Winter/ Season Recommended</h1> */}
      {/* <h1>Recommend</h1> */}
      <CategoryList />
      {/* <CategoriesCarousel /> */}

      <h1>Result</h1>
      <Grid container spacing={2}>
        {result
          .map((item: any) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={item._id}
              ref={lastItemElementRef}>
              <CardItem item={item} />
            </Grid>
          ))}
        {/* <TopFive title={"Top Five Pizza"} /> */}
      </Grid>
      <div>{loading && "Loading..."}</div>
      <div>{error && "Error"}</div>
      <div>{(!hasMore && !loading) && "No More Items"}</div>

    </div>
  );
}

export default TheList;
