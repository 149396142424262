import API from "../../utils/APIs/authAPI";
import { AUTH } from "../constants";
import { createAction } from "../store";
import { getAuth, signOut as facebookSignOut } from "firebase/auth";

export type ActionTypes = { type: typeof AUTH; payload: any };

export const googleSignIn = (formData: any) => async (dispatch: any) => {
  try {
    const { data } = await API.googleSignIn(formData);
    // console.log(data);

    if (data) {
      localStorage.setItem("token", JSON.stringify(data.token));
      dispatch(createAction(AUTH, { user: data.user, token: data.token }));
      window.location.reload();
    }
  } catch (error: any) {
    console.log(error.response.data);
  }
};

export const signIn = (formData: any) => async (dispatch: any) => {
  try {
    const { data } = await API.signIn(formData);
    // console.log(formData);

    if (data) {
      localStorage.setItem("token", JSON.stringify(data.token));

      // dispatch(createAction(AUTH, { user: data.user, token: data.token }));
      window.location.reload();
    }
  } catch (error: any) {
    console.log(error.response.data);
  }
};

export const signOut = () => async (dispath: any) => {
  try {
    localStorage.clear();
    const auth = getAuth();
    auth && (await facebookSignOut(auth));

    // dispath(createAction(LOGOUT, null));
    window.location.reload();
  } catch (error: any) {
    console.log(error);
  }
};
