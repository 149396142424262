import React from "react";

function AssignBusiness() {
  return (
    <>
      <h1>Assign Business</h1>
      <input placeholder="business phone number" />
      <input placeholder="email" />
      <button>Assign</button>
    </>
  );
}

export default AssignBusiness;
