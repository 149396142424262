import axios from "./interceptors";

const BusinessAPI = {
  fetchAllBusinesses: async () => {
    const { data } = await axios.get("/businesses/");
    return data;
  },

  fetchAllBusinessesByPage: async (page: number) => {
    try {
      const { data } = await axios.get(`/businesses/pages/${page}`);
      return data;
    } catch (error) {
      console.log("error in BusinessAPI");
    }
  },

  fetchBusinessDetail: async (business_id: string) => {
    const { data } = await axios.get(`/businesses/${business_id}`);
    return data.business;
  },

  fetchBusinessByPhone: async (phone: string) => {
    try {
      const { data } = await axios.get(`/businesses/phone/${phone}`);
      return data.business;
    } catch (error: any) {
      if (error.response) {
        return error.response;
      }
      return error;
    }
  },

  createBusiness: async (businessForm: any) => {
    try {
      const { data } = await axios.post("/businesses/", businessForm);
      return data;
    } catch (error: any) {
      if (error.response) {
        return error.response;
      }
      return error;
    }
  },
  updateBusiness: async (businessForm: any, businessId: string) => {
    try {
      const { data } = await axios.put(
        `/businesses/${businessId}`,
        businessForm
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  },
  favoriteBusiness: async (businessId: string, signal: string) => {
    try {
      const { data } = await axios.post(`/businesses/favorite/${businessId}`, {
        signal,
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  },
};
export default BusinessAPI;
