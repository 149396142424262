import React from "react";
import TextField from "./TextField/TextField";
import { Paper } from "@mui/material";

function AddressTextField({
  form,
  updateForm,
  view,
}: {
  form: any;
  updateForm: any;
  view: boolean;
}) {
  const convertDisplayAddress = (location: any) => {
    return [
      location.address1,
      location.address2,
      location.address3,
      `${location.city}, ${location.state} ${location.zipCode}`,
    ];
  };

  if (
    view === true &&
    (form.location.displayAddress[3] === ",  " ||
      form.location.displayAddress[3] === "undefined, undefined undefined")
  )
    return null;
  return (
    <div>
      {(form.location.address1 ||
        form.location.address2 ||
        form.location.city ||
        form.location.state ||
        form.location.zipCode ||
        form.location.country) && (
        <TextField type="h2" value="Address" view={true} />
      )}
      {!view ? (
        <Paper elevation={0}>
          <div>
            <TextField
              type="p1"
              name={"address1"}
              value={form.location.address1}
              updateForm={updateForm}
              placeholder={"Address Line 1"}
              view={view}
            />
            <TextField
              type="p1"
              name={"address2"}
              value={form.location.address2}
              updateForm={updateForm}
              placeholder={"Address Line 2"}
              view={view}
            />
          </div>

          <TextField
            type="p1"
            name={"city"}
            value={form.location.city}
            updateForm={updateForm}
            placeholder={"City"}
            view={view}
          />
          <TextField
            type="p1"
            name={"state"}
            updateForm={updateForm}
            value={form.location.state}
            placeholder={"State"}
            view={view}
          />
          <TextField
            type="p1"
            name={"zipCode"}
            value={form.location.zipCode}
            updateForm={updateForm}
            placeholder={"Zip Code"}
            view={view}
          />
          <TextField
            type="p1"
            name={"country"}
            value={form.location.country}
            updateForm={updateForm}
            placeholder={"Country"}
            view={view}
          />
        </Paper>
      ) : (
        <div>
          {convertDisplayAddress(form.location).map(
            (address: any, index: number) => {
              if (address)
                return (
                  <TextField
                    key={index}
                    type="p1"
                    view={view}
                    value={address}
                  />
                );
            }
          )}
        </div>
      )}
    </div>
  );
}

export default AddressTextField;
