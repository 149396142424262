import {
  Card,
  Box,
  CardMedia,
  CardContent,
  Typography,
  CardActionArea,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DEFAULT_IMAGE } from "../../../utils/constant/imageHandler";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";

function BusinessCard({ business }: { business: any }) {
  const navigate = useNavigate();
  const handleCardClick = () => {
    navigate(`/businesses/${business._id}`);
  };

  const toCorrectFormat = (cover: string): string => {
    // console.log(cover);
    if (!cover) return DEFAULT_IMAGE;
    if (cover.startsWith("http")) {
      return cover;
    }
    return `${process.env.REACT_APP_API}/${business.coverPhoto}`;
  };
  // console.log(business.categories);
  // console.log(business);
  return (
    <>
      <Card
        elevation={0}
        sx={{
          minWidth: "280px",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          borderRadius: "1rem",
          border: "1px solid #e0e0e0",
        }}
      >
        <CardActionArea onClick={handleCardClick}>
          <CardMedia
            component="img"
            sx={{
              // borderRadius: "1rem",
              height: "200px",
            }}
            image={toCorrectFormat(business.coverPhoto)}
          />

          <Box>
            <CardContent>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "1rem",
                  // alignItems: "center",
                }}
              >
                <Typography variant="h5">{business.name}</Typography>
                <div
                  style={{
                    display: "flex",
                    // gap: "0.5rem",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <FavoriteRoundedIcon color="action" />
                  <Typography>{business.favoriteCount}</Typography>
                </div>
              </div>
              <Typography variant="subtitle1" color="text.secondary">
                {/* {business.rating} */}
              </Typography>
            </CardContent>
          </Box>
        </CardActionArea>
        {/* <CardActions>
          <Box sx={{ display: "flex" }}>
            {business.categories?.map((category: any) => {
              return <Button key={category._id}>{category.name}</Button>;
            })}
          </Box>
        </CardActions> */}
      </Card>
    </>
  );
}

export default BusinessCard;
