import React from "react";
import MenuList from "../MenuList/MenuList";

function TopicLists({ lists, view }: { lists: any; view: any }) {
  // console.log(lists);
  return (
    <div>
      {lists.map((list: any) => {
        return (
          list.items.length > 0 && (
            <MenuList
              topic={list.name}
              menu={list.items}
              view={view}
              key={list._id}
            />
          )
        );
      })}
    </div>
  );
}

export default TopicLists;
