import React from "react";
import { Button } from "@mui/material";
import AddItemForm from "./AddItemForm";
import withModal from "../../HOC/withModal";

function AddItemButton({
  view,
  topic,
}: {
  view: boolean | undefined;
  topic: string;
}) {
  const ModalForm = withModal(ButtonControl, AddItemForm);
  return (
    <div style={{ marginLeft: 16, marginTop: 32 }}>
      <ModalForm view={view} topic={topic} />
    </div>
  );
}

export default AddItemButton;

const ButtonControl = ({
  openModal,
  view,
}: {
  openModal: any;
  view: boolean | undefined;
}) => {
  return (
    <Button
      onClick={openModal}
      sx={{
        width: 100,
        height: 100,
        margin: 1,
        borderRadius: "10%",
        backgroundColor: "grey",
        display: view ? "none" : "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        color: "black",
      }}
    >
      <img src="https://img.icons8.com/ios/50/000000/plus-math.png" />
      Add Item
    </Button>
  );
};
