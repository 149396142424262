import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";

function GenerateDescriptionButton({
  updateDescription,
  descriptionLoading,
}: any) {
  const onClickHandler = async (e: any) => {
    e.preventDefault();
    updateDescription();
  };
  return (
    <LoadingButton
      size="small"
      loading={descriptionLoading}
      variant="outlined"
      onClick={onClickHandler}
      disabled
    >
      Generate Description
    </LoadingButton>
  );
}

export default GenerateDescriptionButton;
