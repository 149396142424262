import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Chip,
  Divider,
} from "@mui/material";
import { DEFAULT_IMAGE } from "../../utils/constant/imageHandler";
// import LikeDislikeButtons from "../LikeDislikeButtons/LikeDislikeButtons";
import { useNavigate } from "react-router-dom";
import StorefrontIcon from "@mui/icons-material/Storefront";
import { LocationOn } from "@mui/icons-material";

const STRING_MAX_LENGTH = 30;

function CardItem({ item }: { item: any }) {
  const navigate = useNavigate();
  const onClickHandler = () => {
    // console.log(item.businessId);
    // console.log(item)
    navigate(
      `/businesses/${
        typeof item.businessId === "object" && item.businessId._id
          ? item.businessId._id
          : item.businessId
      }`
    );
  };
  return (
    <Card
      elevation={0}
      sx={{
        borderRadius: "1rem",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        border: "1px solid #e0e0e0",
      }}
    >
      <CardActionArea onClick={onClickHandler}>
        <CardMedia
          component="img"
          height="200"
          image={
            item.images[0]
              ? `${process.env.REACT_APP_API}/${item.images[0]}`
              : DEFAULT_IMAGE
          }
          alt="green iguana"
        />
        <CardContent>
          <Typography variant="h5" component="div">
            {item.name}
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "1rem",
            }}
          >
            <Chip
              icon={<StorefrontIcon />}
              label={`${item.businessId.name || item.businessName}`}
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              variant="outlined"
            />
            <Chip
              icon={<LocationOn />}
              label={`${
                item.businessId.location?.city || item.businessCity || ""
              }, 
              ${item.businessId.location?.state || item.businessState || ""}`}
              variant="outlined"
            />
          </div>

          <Typography variant="body2" color="text.secondary">
            {item.description.split(" ").length > STRING_MAX_LENGTH
              ? `${item.description
                  .split(" ")
                  .splice(0, STRING_MAX_LENGTH)
                  .join(" ")}...`
              : item.description}
          </Typography>
        </CardContent>
      </CardActionArea>

      <CardActions sx={{ width: "80%" }}>
        {item.popularity.likes !== 1 ? (
          <Typography>
            {item.popularity.likes} people liked this item
          </Typography>
        ) : (
          <Typography>
            {item.popularity.likes} person liked this item
          </Typography>
        )}

        {/* <LikeDislikeButtons
          popularity={item.popularity}
          itemId={item._id}
          view={true}
        /> */}
      </CardActions>
    </Card>
  );
}

export default CardItem;
