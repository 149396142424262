import axios from "./interceptors";

const ItemAPI = {
  createItem: async ({ businessId, item }: { businessId: any; item: any }) => {
    try {
      const { data } = await axios.post(`/items/${businessId}`, item);
      //delay 1 second so that s3 can upload the image
      //not a good solution, but it works for now
      await new Promise((resolve) => setTimeout(resolve, 2000));
      return data;
    } catch (error) {
      console.log(error);
    }
  },

  fetchItemsByPage: async (page: number) => {
    try {
      const { data } = await axios.get(`/items/pages/${page}`);
      return data;
    } catch {
      console.log("error in itemAPI");
    }
  },

  getAllItems: async () => {
    try {
      const { data } = await axios.get("/items");
      return data;
    } catch (error) {
      console.log(error);
    }
  },

  fetchItemDetail: async (item_id: string) => {
    try {
      const { data } = await axios.get(`/items/${item_id}`);
      return data;
    } catch (error) {
      console.log(error);
    }
  },

  likeItem: async (item_id: string) => {
    try {
      const { data } = await axios.post(`/items/vote/${item_id}`, {
        vote: "like",
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  },
  unlikeItem: async (item_id: string) => {
    try {
      const { data } = await axios.post(`/items/vote/${item_id}`, {
        vote: "unlike",
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  },
  dislikeItem: async (item_id: string) => {
    try {
      const { data } = await axios.post(`/items/vote/${item_id}`, {
        vote: "dislike",
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  },
  undislikeItem: async (item_id: string) => {
    try {
      const { data } = await axios.post(`/items/vote/${item_id}`, {
        vote: "undislike",
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  },
};
export default ItemAPI;
