import React from "react";
import { useDispatch } from "react-redux";
import { GoogleLogin } from "@react-oauth/google";
import { GoogleOAuthProvider } from "@react-oauth/google";
// import { AUTH } from "../../constants/actionTypes";
import { Box, Button } from "@mui/material";
import { googleSignIn } from "../../redux/actions/authAction";
import { Google } from "@mui/icons-material";
function GoogleSignIn() {
  const dispatch = useDispatch<any>();

  const googleSuccess = async (res: any) => {
    try {
      const token = res.credential;

      //   console.log(token);
      dispatch(googleSignIn({ token }));
    } catch (error) {
      console.log(error);
    }
  };

  const googleError = (error: unknown) => {
    console.log(error);
    console.log("Google Sign In was unsuccessful. Try again later");
  };

  return (
    <Box
      sx={{
        marginTop: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <GoogleOAuthProvider
      
        clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENTID as string}
      >
        <GoogleLogin onSuccess={googleSuccess} onError={() => googleError} />
      </GoogleOAuthProvider>
    </Box>
  );
}

export default GoogleSignIn;


