import { legacy_createStore, applyMiddleware, compose, AnyAction } from "redux";
import thunk, { ThunkAction, ThunkDispatch } from "redux-thunk";
import rootReducer from "./reducers";

export default legacy_createStore(rootReducer, {}, compose(applyMiddleware(thunk)));
export const createAction = (type: string, payload: any) => ({ type, payload });

// Types https://github.com/reduxjs/redux-toolkit/issues/587
export type ReduxState = ReturnType<typeof rootReducer>;
export type TypedDispatch = ThunkDispatch<ReduxState, any, AnyAction>;
export type TypedThunk<ReturnType = void> = ThunkAction<ReturnType, ReduxState, unknown, AnyAction>;
export type TypedGetState = () => ReduxState;