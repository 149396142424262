import { Typography } from "@mui/material";
import React from "react";

function Cooldown({ cooldownPeriod }: { cooldownPeriod: number }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Typography variant="h5" sx={{ fontWeight: "bold", mt: "1rem" }}>
        On cool down, have another coffee.
      </Typography>
      <Typography>{`Please try again in ${cooldownPeriod} days`}</Typography>
    </div>
  );
}

export default Cooldown;
