import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { Button, CardMedia, IconButton, TextField } from "@mui/material";
import withModal from "../../HOC/withModal";
import { DEFAULT_IMAGE } from "../../../utils/constant/imageHandler";
import TagsInput from "../AddItemButton/TagsInput";
import { useDispatch } from "react-redux";
import { updateItem } from "../../../redux/actions/myBusinessAction";

function ItemEditingButton({ item }: { item: any }) {
  const EdittingModal = withModal(OpenModelButton, ModalView);
  return <EdittingModal item={item} />;
}

export default ItemEditingButton;

const OpenModelButton = ({ openModal }: { openModal: any }) => {
  return (
    <IconButton
      aria-label="delete"
      size="medium"
      color="secondary"
      onClick={openModal}
      sx={{
        border: "1px solid purple",
      }}
    >
      <EditIcon />
    </IconButton>
  );
};

const ModalView = ({ item }: { item: any }) => {
  const [menuForm, setMenuForm] = useState<any>({
    images: item.images,
    description: item.description,
    price: item.price,
  });
  const [tags, setTags] = useState<any>(item.tags.map((tag: any) => tag.name));
  const dispatch = useDispatch<any>();
  const [newImage, setNewImage] = useState<any>();
  const getImage = () => {
    if (newImage) return URL.createObjectURL(newImage);
    if (menuForm && menuForm.images.length > 0)
      return `${process.env.REACT_APP_API}/${item.images[0]}`;
    return DEFAULT_IMAGE;
  };
  const handleTagChange = (event: any, value: any) => {
    // setMenuForm((prevState: any) => ({
    //   ...prevState,
    //   tags: value,
    // }));
    setTags(value);
  };

  const onChangeHandler = (event: any) => {
    if (event.target.name === "images") {
      setNewImage(event.target.files[0]);
      return setMenuForm((prevState: any) => ({
        ...prevState,
        [event.target.name]: event.target.files[0],
      }));
    }
    setMenuForm((prevState: any) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  const onClickHandler = (event: any) => {
    // event.preventDefault();
    const formData = new FormData();
    for (let key in menuForm) {
      formData.append(key, menuForm[key]);
    }
    formData.append("businessId", item.businessId);
    tags.forEach((tag: any, index: number) => {
      formData.append(`tags[${index}]`, tag);
    });
    // for (let key of formData.keys()) {
    //   console.log(key, formData.get(key));
    // }
    dispatch(updateItem(formData, item._id));
  };
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <h1>{item.name}</h1>
      <CardMedia
        component="img"
        height="300"
        sx={{ borderRadius: "1rem", width: "100%" }}
        image={getImage()}
        alt={item.name}
      />
      <input type="file" name="images" onChange={onChangeHandler} />
      <TagsInput tags={tags} handleTagChange={handleTagChange} />
      <TextField
        id="menu-description"
        label="Menu Description"
        variant="outlined"
        name="description"
        fullWidth
        multiline
        rows={4}
        value={menuForm?.description || ""}
        sx={{ m: 1 }}
        onChange={onChangeHandler}
      />

      <TextField
        id="menu-price"
        label="Menu Price"
        variant="outlined"
        name="price"
        fullWidth
        value={menuForm.price || ""}
        sx={{ m: 1 }}
        onChange={onChangeHandler}
      />
      <Button onClick={onClickHandler} variant="outlined" type="submit">
        Edit Item
      </Button>
    </div>
  );
};
