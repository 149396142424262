import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";

function GenerateImageButton({ updateImage, imageLoading }: any) {
  const onClickHandler = async (e: any) => {
    e.preventDefault();
    updateImage();
  };

  return (
    <LoadingButton
      disabled
      size="small"
      loading={imageLoading}
      variant="outlined"
      onClick={onClickHandler}
    >
      Generate Photo
    </LoadingButton>
  );
}

export default GenerateImageButton;
