import React from 'react'
import { Button } from '@mui/material'
import BusinessAPI from '../../../utils/APIs/businessAPI'
import { useDispatch } from 'react-redux'
import { createNewBusiness } from '../../../redux/actions/myBusinessAction'

function CreateBusinessButton({ setForm, phone }: { setForm: any, phone: string }) {
    const dispatch = useDispatch<any>()
    const onButtonClick = async () => {
        if (!phone) return alert('Please enter a phone number')
        dispatch(createNewBusiness({ displayPhone: phone }))
        // const new_business = await BusinessAPI.createBusiness({ displayPhone: phone })
        // setForm(new_business)
    }



    return (
        <Button variant="outlined" onClick={onButtonClick}>Create</Button>
    )
}

export default CreateBusinessButton