import { AnyAction } from "redux";
import {FETCH_USER_PROFILE} from "../constants";

export default function userReducer(prevState: any = {}, action: AnyAction) {
  switch (action.type) {
    case FETCH_USER_PROFILE:
      return action.payload;

    default:
      return prevState;
  }
}