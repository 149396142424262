import { Box, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import * as Realm from "realm-web";
import BusinessCard from "../components/Businesses/BusinessesList/BusinessCard";
import CardItem from "../components/TheList/CardItem";

function Search() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const input = searchParams.get("input");
  const [itemsResult, setItemsResult] = useState<any>([]);
  const [businessesResult, setBusinessesResult] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const run = async () => {
      setIsLoading(true);
      const apiKey = process.env.REACT_APP_REALM_API_KEY;
      if (!apiKey) {
        throw new Error("Could not find a Realm Server API Key.");
      }
      const app = new Realm.App({
        id: process.env.REACT_APP_REALM_APP_ID as string,
      });
      // Create an api key credential
      const credentials = Realm.Credentials.apiKey(apiKey);
      try {
        const user = await app.logIn(credentials);
        const itemsSearchResult = await user.functions.searchItemsFunction(
          input
        );
        const businessesSearchResult =
          await user.functions.searchBusinessesFunction(input);
        // console.log(user);
        setItemsResult(itemsSearchResult);
        setBusinessesResult(businessesSearchResult);
        setIsLoading(false);
      } catch (err) {
        if (err instanceof Error) {
          console.error("Failed to log in", err.message);
        }
      }
    };
    input && run();
  }, [input]);
  console.log(itemsResult);
  return (
    <Box>
      {/* <h1>Sponsored</h1> */}
      {/* businesses result should be 4 items max */}
      <h1>Search result for {input}</h1>


      {(businessesResult.length > 0 || isLoading) && (
        <div>
          <h2>Businesses</h2>
          {isLoading && <p>Loading...</p>}
          <Stack
            direction="row"
            spacing={2}
            sx={{
              width: "100%",
              padding: "0.5rem",
              overflowX: "scroll",
              overflowY: "hidden",
              backgroundColor: "#fff",
            }}
          >

            {businessesResult
              .sort((a: any, b: any) => b.favoriteCount - a.favoriteCount)
              .map((business: any) => {
                return (
                  <Grid key={business._id} item xs={12} sm={6} md={4} lg={3}>
                    <BusinessCard business={business} />
                  </Grid>
                );
              })}
          </Stack>
        </div>

      )}

      <h2>Items</h2>
      {isLoading && <p>Loading...</p>}
      {itemsResult.length > 0 || isLoading ? (
        <Grid container spacing={2}>
          {itemsResult
            .sort((a: any, b: any) => b.popularity.likes - a.popularity.likes)
            .map((item: any) => {
              return (
                <Grid item xs={12} sm={6} md={3} key={item._id}>
                  <CardItem item={item} />
                </Grid>
              );
            })}
        </Grid>
      ) : (
        <p>No Item Found</p>
      )}
    </Box>
  );
}

export default Search;
