import React from "react";
import {
  Typography,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Stack,
  Chip,
  IconButton,
  Button,
  Divider,
} from "@mui/material";
import { DEFAULT_IMAGE } from "../../../utils/constant/imageHandler";
import { deleteItem } from "../../../redux/actions/myBusinessAction";
import { useDispatch, useSelector } from "react-redux";
import LikeDislikeButtons from "../../LikeDislikeButtons/LikeDislikeButtons";
import DeleteIcon from "@mui/icons-material/Delete";
import ItemEditingButton from "./ItemEditingButton";
import RestaurantMenuRoundedIcon from "@mui/icons-material/RestaurantMenuRounded";
import { useNavigate } from "react-router-dom";

export default function Item({
  item,
  view,
}: {
  item: any;
  view: boolean | undefined;
}) {
  const dispatch = useDispatch<any>();
  const user = useSelector((state: any) => state.user);
  const navigate = useNavigate()

  const displayPrice = () => {
    if (item.price) return `$${item.price}`;
    return null;
  };

  const chipClickHandler = (tagName: any) => {
    navigate(`/search?input=${tagName}`)
  };


  const handleDeleteButton = () => {
    // console.log(item)
    dispatch(deleteItem(item._id, item.businessId));
  };

  // console.log(item);
  return (
    <Card
      elevation={0}
      sx={{
        // maxWidth: 600,
        width: 330,
        flexShrink: 0,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        borderRadius: "1rem",
        border: "1px solid #e0e0e0",
      }}
    >
      {/* <CardActionArea> */}
      <CardMedia
        component="img"
        height="250"
        src={
          item.images[0]
            ? `${process.env.REACT_APP_API}/${item.images[0]}`
            : DEFAULT_IMAGE
        }
        alt="image"
      />
      <CardContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "1rem",
          }}
        >
          <Typography gutterBottom variant="h5" component="div">
            {item.name}
          </Typography>
          {user?.role !== "admin" && (
            <LikeDislikeButtons
              itemId={item._id}
              view={view}
              popularity={
                item.popularity ? item.popularity : { likes: 0, dislikes: 0 }
              }
            />
          )}
        </div>

        <Typography variant="body2" color="text.secondary">
          {item.description && item.description}
        </Typography>
        <Typography variant="h6" color="text.secondary">
          {displayPrice()}
        </Typography>
      </CardContent>
      {/* </CardActionArea> */}

      <CardActions
        sx={{
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          gap: "0.5rem",
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          sx={{
            width: "100%",
            overflowX: "scroll",
            overflowY: "hidden",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {item.tags &&
            item.tags.map((tag: any) => (
              <Chip label={tag.name} key={tag._id} onClick={() => chipClickHandler(tag.name)} />
            ))}
          {/* <Chip label="Chip Filled" />
          <Chip label="Chip Outlined" variant="outlined" /> */}
        </Stack>

        {view ? (
          <div
            style={{
              width: "80%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {user?.role === "admin" && (
              <>
                <LikeDislikeButtons
                  itemId={item._id}
                  view={view}
                  popularity={
                    item.popularity
                      ? item.popularity
                      : { likes: 0, dislikes: 0 }
                  }
                />
                <Divider orientation="vertical"></Divider>
                <Button
                  variant="outlined"
                  sx={{ textTransform: "none", fontSize: "0.8rem" }}
                  startIcon={<RestaurantMenuRoundedIcon />}
                >
                  Feedback
                </Button>
              </>
            )}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              width: "80%",
              justifyContent: "space-between",
            }}
          >
            <ItemEditingButton item={item} />
            <IconButton
              aria-label="delete"
              size="medium"
              color="error"
              onClick={handleDeleteButton}
              sx={{
                border: "1px solid red",
              }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        )}
      </CardActions>
    </Card>
  );
}
