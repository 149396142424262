import React from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Container, Box, Avatar, Typography, Divider } from "@mui/material";
import GoogleSignIn from "./GoogleSignIn";
import FacebookSignIn from "./FacebookSignIn";
import PasswordSignIn from "./PasswordSignIn";

function SignIn({ title }: { title: string }) {
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: 5,
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {title}
        </Typography>

        {/* <PasswordSignIn /> */}

        <GoogleSignIn />
        <Typography component="h2" margin={2}>
          <Divider>or</Divider>
        </Typography>

        <FacebookSignIn />
      </Box>
    </Container>
  );
}

export default SignIn;
