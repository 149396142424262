import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import withModal from "../HOC/withModal";
import AddItemForm from "./AddItemButton/AddItemForm";

function AddTopicButton({ view }: { view: boolean }) {
  const AddNewTopic = withModal(ButtonController, ModalViewNewTopicForm);
  // console.log(view);
  return <>{!view && <AddNewTopic />}</>;
}

export default AddTopicButton;

const ButtonController = ({ openModal }: { openModal: any }) => {
  return (
    <Button variant="outlined" onClick={openModal}>
      Add New List
    </Button>
  );
};


const ModalViewNewTopicForm = () => {
  const [input, setInput] = useState("");
  const onChangeHandler = (e: any) => {
    setInput(e.target.value);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", marginTop: 10 }}>
      <TextField label="Topic" onChange={onChangeHandler} />
      <AddItemForm topic={input} />
    </div>
  );
};
