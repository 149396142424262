import { useState } from "react";
import AddCoverImageButton from "./AddCoverImageButton/AddCoverImageButton";
import TextField from "./TextField/TextField";
import { useDispatch, useSelector } from "react-redux";
import { Button, FormControlLabel, FormGroup, Switch } from "@mui/material";
import {
  clearBusiness,
  updateBusiness,
} from "../../redux/actions/myBusinessAction";
import AddressTextField from "./AddressTextField";
import DescriptionTextField from "./DescriptionTextField";
import TopicLists from "./TopicLists/TopicLists";
import AddTopicButton from "./AddTopicButton";
import FavoriteButton from "./FavoriteButton";
import FeedbackButton from "../FeedbackButton/FeedbackButton";

function MyBusinessDetail({
  form,
  updateForm,
  view,
  businessId,
  openFeedbackModal
}: {
  form: any;
  updateForm: any;
  view: boolean;
  businessId?: string;
  openFeedbackModal: boolean;
}) {
  const dispatch = useDispatch<any>();
  const [viewOnly, setViewOnly] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state: any) => state.user);

  const handleSwitch = (e: any) => {
    setViewOnly(!viewOnly);
  };
  const handleSave = (e: any) => {
    // e.preventDefault()
    setIsLoading(true);
    const formData = new FormData();
    for (let key in form) {
      // console.log(key, form[key]);

      if (key === "list") {
        continue;
        //do nothing, because we don't want to send list to the server
      } else if (key === "location") {
        for (let key2 in form.location) {
          formData.append(`location[${key2}]`, form.location[key2]);
        }
      } else if (key === "categories") {
        form.categories.forEach((category: any, index: number) => {
          formData.append(`categories[${index}]`, category);
        });
      } else {
        // console.log(key, form[key]);
        formData.append(key, form[key]);
      }
    }

    // interate the formData and console.log the key and value
    // for (let key of formData.keys()) {
    //   console.log(key, formData.get(key));
    // }

    dispatch(updateBusiness(formData, form._id));
    setIsLoading(false);
  };
  // console.log(form);
  return (
    <>
      <div
        style={{
          display: view ? "none" : "flex",
          justifyContent: "space-between",
        }}
      >
        <Button variant="outlined" onClick={() => dispatch(clearBusiness())}>
          Back
        </Button>
        <FormGroup
          sx={{
            position: "fixed",
            zIndex: 1,
            bottom: "4rem",
            right: "2rem",
            backgroundColor: "white",
            borderRadius: "10px",
          }}
        >
          <FormControlLabel
            onChange={handleSwitch}
            control={<Switch defaultChecked />}
            label={viewOnly ? "Previewing" : "Editing"}
          />
        </FormGroup>

        <Button
          variant="outlined"
          color="primary"
          disabled={isLoading}
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          gap: "1rem",
          justifyContent: "flex-end",
          marginTop: "0.5rem",
        }}
      >
        <FavoriteButton businessId={form._id} count={form.favoriteCount} />
        {(user?.role === "admin" || businessId === '642cb43be823281ff725b887') && (
          <FeedbackButton businessId={businessId} triggerOpen={openFeedbackModal} />
        )}
      </div>
      <div style={{ marginTop: "0.5rem" }}>
        <AddCoverImageButton
          view={view || viewOnly}
          updateForm={updateForm}
          form={form}
        />
      </div>
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          top: -30,
          gap: 10,
        }}
      >
        <div>
          <div style={{ width: "95%" }}>
            <TextField
              value={form.name}
              updateForm={updateForm}
              name={"name"}
              placeholder={"Business Name"}
              type="h1"
              view={view || viewOnly}
            />
          </div>

          <TextField
            value={form.displayPhone}
            updateForm={updateForm}
            name={"displayPhone"}
            placeholder={"Phone Number"}
            type="p1"
            // view={props.view || viewOnly}
            view={true}
          />
        </div>

        {form.description && (
          <TextField value={"Description"} type="h2" view={true} />
        )}
        <DescriptionTextField
          form={form}
          updateForm={updateForm}
          view={view || viewOnly}
        />
        <AddressTextField
          form={form}
          // form={""}
          updateForm={updateForm}
          view={view || viewOnly}
        />
        <TopicLists lists={form.lists} view={view || viewOnly} />
      </div>
      <AddTopicButton view={view || viewOnly} />
      {/* <AddFooterButton /> */}
    </>
  );
}

export default MyBusinessDetail;
