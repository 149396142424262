import { Grid } from "@mui/material";
// import { getBusinesByPhone } from "../../redux/actions/myBusinessAction";
// import { useDispatch } from "react-redux";
import BusinessCard from "./BusinessCard";

function MyBusinessList({ businessesList }: any) {
  return (
    <div>
      <h1>My Businesses</h1>
      <Grid container spacing={2}>
        {businessesList.map((business: any) => (
          <Grid key={business._id} item xs={12} sm={6} md={4} lg={3}>
            <BusinessCard
              name={business.name}
              phone={business.phone}
              displayPhone={business.displayPhone}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default MyBusinessList;
