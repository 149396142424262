import { useCallback, useRef, useState } from "react";
import BusinessCard from "../components/Businesses/BusinessesList/BusinessCard";
import { Grid } from "@mui/material";
import Loading from "../components/Loading/Loading";
import useFetchByPage from "../components/hooks/useBusinessSearch";

//https://www.youtube.com/watch?v=NZKUirTtxcg&t=449s
//infinite scroll reference

function BusinessesList(): JSX.Element {
  // const [businesses, setBusinesses] = useState<any>();
  const [pageNumber, setPageNumber] = useState(1);
  const { result, hasMore, loading, error } = useFetchByPage(pageNumber, "business")
  const observer = useRef<any>()
  const lastBusinessElementRef = useCallback((node: any) => {
    if (loading) return
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPageNumber(prevPageNumber => prevPageNumber + 1)
      }
    })
    if (node) observer.current.observe(node)
  }, [loading, hasMore])

  if (!result) return <Loading />;
  return (
    <>
      {/* <h1>Sponsored Businesses</h1> */}
      {/* <h1>Top 10 Loved Vietnamese Businesses In U.S</h1> */}
      {/* <h1>Top 10 Loved Vietname0se Businesses in Your Area</h1> */}
      {/* <h1>Top 10 Loved Vietnamese Businesses in Seattle</h1> */}
      {/* <h1>Opening Soon Businesses</h1> */}

      <h1>Business List</h1>
      <Grid container spacing={2}>
        {result.map((business: any, index: number) => {
          if (result.length === index + 1) {
            return (
              <Grid
                key={business._id} item xs={12} sm={6} md={4} lg={3}
                ref={lastBusinessElementRef}
              >
                <BusinessCard business={business} />
              </Grid>
            );
          }
          else {
            return (
              <Grid key={business._id} item xs={12} sm={6} md={4} lg={3}>
                <BusinessCard business={business} />
              </Grid>
            );
          }
        })}
      </Grid>
      <div>{loading && "Loading..."}</div>
      <div>{error && "Error"}</div>
      <div>{(!hasMore && !loading) && "No More Businesses"}</div>

      {/* {businesses.length < 1 ? (
        <p>No Business Found</p>
      ) : (
        <Grid container spacing={2}>
          {businesses
            .sort((a: any, b: any) => b.favoriteCount - a.favoriteCount)
            .map((business: any, index: number) => {
              return (
                <Grid key={business._id} item xs={12} sm={6} md={4} lg={3}>
                  <BusinessCard business={business} />
                </Grid>
              );
            })}
        </Grid>
      )} */}
    </>
  );
}

export default BusinessesList;
