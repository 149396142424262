import React from "react";
import { signInWithPopup, FacebookAuthProvider } from "firebase/auth";
import { authentication } from "../../utils/configs/firebase-config";
import { useDispatch } from "react-redux";
import { googleSignIn } from "../../redux/actions/authAction";
import { Button } from "@mui/material";
import { Facebook } from "@mui/icons-material";

function FacebookSignIn() {
  const dispatch = useDispatch<any>();
  const handleFacebookSignIn = async () => {
    try {
      const provider = new FacebookAuthProvider();
      const result: any = await signInWithPopup(authentication, provider);
      dispatch(googleSignIn({ token: result.user.accessToken }));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<Facebook />}
      onClick={handleFacebookSignIn}
    >
      Continue with Facebook
    </Button>
  );
}

export default FacebookSignIn;
