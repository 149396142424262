import axios from "./interceptors";
import { v4 as uuidv4 } from "uuid";

const DiscountAPI = {
  getDiscountStatus: async () => {
    let uniqueId = localStorage.getItem("uniqueId");
    if (!uniqueId) {
      const uuid = uuidv4();
      localStorage.setItem("uniqueId", uuid);
      uniqueId = uuid;
    }
    const response = await axios.get(`/discount/status/${uniqueId}`);

    // const response = await axios.get(`/discount/status/${1}`);
    return response.data;
  },
  redeemDiscount: async () => {
    const { message }: any = await axios.post("/discount/redeem", {
      userId: localStorage.getItem("uniqueId"),
    });
    return message;
  },
};

export default DiscountAPI;
