import React, { useState } from "react";
import { Button, CardMedia, TextField } from "@mui/material";
import { DEFAULT_IMAGE } from "../../../utils/constant/imageHandler";
import GenerateImageButton from "./GenerateImageButton";
import OpenAIAPI from "../../../utils/APIs/openAIAPI";
import GenerateDescriptionButton from "./GenerateDescriptionButton";
import { fileToBase64 } from "../../../utils/shared/script";
import { createNewItem } from "../../../redux/actions/myBusinessAction";
import { useDispatch, useSelector } from "react-redux";
import TagsInput from "./TagsInput";

const emptyMenu = {
  name: "",
  description: "",
  price: "",
  image: "",
};

const AddItemForm = ({ topic }: { topic: string }) => {
  const [menuForm, setMenuForm] = useState<any>(emptyMenu);
  const [imageLoading, setImageLoading] = useState(false);
  const [descriptionLoading, setDescriptionLoading] = useState(false);
  const [tags, setTags] = useState<any>([]);
  const handleTagChange = (event: any, value: any) => {
    setTags(value);
  };

  const business = useSelector((state: any) => state.business);
  const dispatch = useDispatch<any>();
  // console.log(business)
  const onChangeHandler = async (event: any) => {
    if (event.target.name === "images") {
      // console.log(event.target.files[0]);
      // const base64 = await fileToBase64(event.target.files[0]);
      return setMenuForm((prevState: any) => ({
        ...prevState,
        [event.target.name]: event.target.files[0],
      }));
    }
    setMenuForm((prevState: any) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  //   console.log(menuForm);
  const onClickHandler = (event: any) => {
    // event.preventDefault();
    const formData = new FormData();
    for (let key in menuForm) {
      formData.append(key, menuForm[key]);
    }

    tags.forEach((tag: any, index: number) => {
      formData.append(`tags[${index}]`, tag);
    });
    formData.append("topic", topic);
    // for (let key of formData.keys()) {
    //   console.log(key, formData.get(key));
    // }
    dispatch(createNewItem(formData, business._id));
  };

  const updateImage = async () => {
    setImageLoading(true);
    const image = await OpenAIAPI.fetchAIImage(menuForm.name);
    setMenuForm((prevState: any) => ({
      ...prevState,
      image: `data:image/jpeg;base64,${image}`,
    }));
    setImageLoading(false);
  };

  const updateDescription = async () => {
    setDescriptionLoading(true);
    const description = await OpenAIAPI.fetchItemDescription(menuForm.name);
    setMenuForm((prevState: any) => ({ ...prevState, description }));
    setDescriptionLoading(false);
  };
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <h1>{`Add Item to ${topic}`}</h1>
      <CardMedia
        component="img"
        height="300"
        sx={{ borderRadius: "1rem", width: "100%" }}
        // image from OpenAI is in base64 format, and store in image (not images)
        // to display, use menuForm.image without createObjectURL
        // image={menuForm.image ? menuForm.image : DEFAULT_IMAGE}
        image={
          menuForm.images ? URL.createObjectURL(menuForm.images) : DEFAULT_IMAGE
        }
        alt={menuForm.name}
      />
      <input type="file" name="images" onChange={onChangeHandler} />
      <TextField
        id="menu-name"
        label="Menu Name"
        variant="outlined"
        name="name"
        fullWidth
        sx={{ m: 1 }}
        onChange={onChangeHandler}
      />
      <TagsInput tags={tags} handleTagChange={handleTagChange} />
      {menuForm.name && (
        <GenerateImageButton
          updateImage={updateImage}
          imageLoading={imageLoading}
        />
      )}
      <TextField
        id="menu-description"
        label="Menu Description"
        variant="outlined"
        name="description"
        fullWidth
        multiline
        rows={4}
        value={menuForm.description}
        sx={{ m: 1 }}
        onChange={onChangeHandler}
      />
      {menuForm.name && (
        <GenerateDescriptionButton
          updateDescription={updateDescription}
          descriptionLoading={descriptionLoading}
        />
      )}
      <TextField
        id="menu-price"
        label="Menu Price"
        variant="outlined"
        name="price"
        fullWidth
        value={menuForm.price}
        sx={{ m: 1 }}
        onChange={onChangeHandler}
      />
      <Button onClick={onClickHandler} variant="outlined" type="submit">
        Add Item
      </Button>
    </div>
  );
};
export default AddItemForm;
