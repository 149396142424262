import { Box, IconButton, Modal, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import React, { useState } from "react";

const withModal = (ControllerComponent: any, DisplayComponent: any) => {
  return (props: any) => {
    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
      setIsOpen(true);
    };

    const closeModal = () => {
      setIsOpen(false);
    };
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "background.paper",
      borderRadius: "1rem",
      // padding: "10px 2px",
      // border: '2px solid #000',
      // boxShadow: 24,
      // p: 4,
    };

    const inner = {
      maxHeight: "75vh",
      "@media(max-width: 900px)": {
        width: "60vw",
      },
      "@media(max-width: 700px)": {
        width: "80vw",
      },
      width: "500px",
      overflow: "auto",
      bgcolor: "background.paper",
      borderRadius: "1rem",
      marginLeft: 4,
      marginRight: 4,
      marginBottom: 4,
    };

    return (
      <>
        <ControllerComponent openModal={openModal} view={props.view} {...props} />
        <Modal
          open={isOpen}
          onClose={closeModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Stack>
            <Box sx={style}>
              <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                <IconButton aria-label="close" onClick={closeModal}>
                  <CloseIcon fontSize="large" />
                </IconButton>
              </div>
              <Box sx={inner}>
                <DisplayComponent {...props} />
              </Box>
            </Box>
          </Stack>
        </Modal>
      </>
    );
  };
};

export default withModal;
