import React, { useState } from 'react'
import CoverImage from './CoverImage'

function AddCoverImageButton({ updateForm, form, view }: { updateForm: any, form: any, view: boolean }) {

    const [hasCover, setHasCover] = useState<boolean>(true)
    const [hasLogo, setHasLogo] = useState<boolean>(true)


    return (
        <div>
            <CoverImage
                hasCover={hasCover}
                hasLogo={hasLogo}
                updateForm={updateForm}
                form={form} 
                view={view}/>
            {/* <button
                style={{ marginRight: 10 }}
                onClick={() => setHasCover(prev => !prev)}
            >{hasCover ? "Remove Cover" : "Add Cover"}</button>
            <button
                onClick={() => setHasLogo(prev => !prev)}
            >{hasLogo ? "Remove Logo" : "Add Logo"}</button> */}

        </div>
    )
}

export default AddCoverImageButton