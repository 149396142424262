import React, { useState, MouseEvent } from "react";
import { Button, Modal, Box, IconButton } from "@mui/material";
import Auth from "./Auth";
import { outline, signinContainer } from "./styles";
import CloseIcon from "@mui/icons-material/Close";

function SignInButton() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleMouseEnter = (e: MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.style.color = "#FF7B00";
  };
  const handleMouseLeave = (e: MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.style.color = "black";
  };
  return (
    <>
      <Button
        key="Sign In"
        onClick={handleOpen}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{
          my: 2,
          //   color: "white",
          display: "block",
          fontFamily: "inherit",
          textTransform: "none",
        }}
      >
        Sign In
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={outline}>
          <IconButton
            aria-label="close"
            // style={{ float: "right" }}
            onClick={handleClose}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
          <Box sx={signinContainer}>
            <Auth title="Sign In" />
          </Box>

          {/* <ChildModal /> */}
        </Box>
      </Modal>
    </>
  );
}

export default SignInButton;
