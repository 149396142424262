import { Box, Button, IconButton, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import { useDispatch, useSelector } from "react-redux";
import { objectIsEmpty } from "../../utils/shared/script";
import BusinessAPI from "../../utils/APIs/businessAPI";
import { updateUserProfile } from "../../redux/actions/userAction";
import Auth from "../Auth/Auth";
import { outline, signinContainer } from "../Auth/styles";
import CloseIcon from "@mui/icons-material/Close";

function FavoriteButton({
  businessId,
  count,
}: {
  businessId: string;
  count: number;
}) {
  const [open, setOpen] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [favoriteCount, setFavoriteCount] = useState(count);
  const userProfile = useSelector((state: any) => state.user);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<any>();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  useEffect(() => {
    const run = async () => {
      if (businessId) {
        const favoriteList = userProfile.favorites
          ? userProfile.favorites.map((business: any) => business._id)
          : [];
        setIsFavorite(favoriteList.includes(businessId));
      }
    };
    run();
  }, [userProfile]);
  const onClickHandler = async () => {
    if (objectIsEmpty(userProfile)) {
      handleOpen();
    } else {
      let response;
      if (isFavorite) {
        setLoading(true);
        response = await BusinessAPI.favoriteBusiness(businessId, "unfavorite");
        setLoading(false);
        setIsFavorite(false);
      } else {
        setLoading(true);
        response = await BusinessAPI.favoriteBusiness(businessId, "favorite");
        setLoading(false);
        setIsFavorite(true);
      }
      // console.log(response.data);
      setFavoriteCount(response.data.favoriteCount);
      //update user profile to redux
      dispatch(updateUserProfile());
    }
  };

  return (
    <div>
      {favoriteCount}
      <Button onClick={onClickHandler} disabled={!loading ? false : true}>
        {isFavorite ? (
          <FavoriteRoundedIcon fontSize="large" />
        ) : (
          <FavoriteBorderRoundedIcon fontSize="large" />
        )}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={outline}>
          <IconButton
            aria-label="close"
            // style={{ float: "right" }}
            onClick={handleClose}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
          <Box sx={signinContainer}>
            <Auth title="Please Sign In to ♡" />
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default FavoriteButton;
