import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BusinessAPI from "../../../utils/APIs/businessAPI";
import Loading from "../../Loading/Loading";
import MyBusinessDetail from "../../MyBusiness/MyBusinessDetail";

function BusinessDetail({ openFeedbackModal }: { openFeedbackModal: boolean }) {
  const params = useParams();
  const [businessDetails, setBusinessDetails] = useState<any>();
  // console.log({params: params.id, DEFAULT_IMAGE});


  useEffect(() => {
    const fetchData = async () => {
      if (params.id) {
        const details = await BusinessAPI.fetchBusinessDetail(params.id);
        setBusinessDetails(details);
      }
    };
    fetchData();
  }, [params.id]);

  // console.log(businessDetails)
  if (!businessDetails) return <Loading />;
  return (
    <MyBusinessDetail
      updateForm={() => { }}
      view={true}
      form={businessDetails}
      businessId={params.id}
      openFeedbackModal={openFeedbackModal}
    />
  );
}

export default BusinessDetail;
