import React, { useState } from "react";
import SignIn from "./SignIn";
import SignUp from "./SignUp";

function Auth({ title }: { title: string }) {
  const [isSignup, setIsSignup] = useState(true);
  return <>{isSignup ? <SignIn title={title}/> : <SignUp setIsSignup={setIsSignup} />}</>;
}

export default Auth;
