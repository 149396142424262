import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  Rating,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import { IconContainerProps } from "@mui/material/Rating";
import BusinessAPI from "../../utils/APIs/businessAPI";
import { useMediaQuery } from "@mui/material";

function FeedbackSection({
  question,
  labels,
  businessId,
  updateFeedbackForm,
  index,
  ratingValue,
}: {
  question: string;
  labels: string[];
  businessId?: string;
  updateFeedbackForm: any;
  index: number;
  ratingValue: number | null;
}) {
  const [value, setValue] = useState<number | null>(
    question.includes("suggestions") ? 5 : null
  );
  const [itemSelected, setItemSelected] = React.useState<string | null>(null);
  const [menu, setMenu] = useState<any>([]);
  const [feedback, setFeedback] = useState<any>({ question, labels });
  const [checked, setChecked] = useState(false);

  const checkedHandler = (event: any) => {
    setChecked(event.target.checked);
  };
  const StyledRating = styled(Rating)(({ theme }) => ({
    "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
      color: theme.palette.action.disabled,
    },
    "& .MuiSvgIcon-root": {
      fontSize: "50px", // Adjust the icon size here
    },
  }));
  const isMobile = useMediaQuery("(max-width:600px)");
  useEffect(() => {
    const feedback = { question, labels };
    updateFeedbackForm(feedback);
    const getMenu = async () => {
      const response =
        businessId && (await BusinessAPI.fetchBusinessDetail(businessId));
      setMenu(response?.lists);
    };
    getMenu();
  }, [businessId]);

  useEffect(() => {
    setValue(ratingValue);
  }, [ratingValue]);

  function IconContainer(props: IconContainerProps) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
  }
  const customIcons: {
    [index: string]: {
      icon: React.ReactElement;
      label: string;
    };
  } = {
    1: {
      icon: <SentimentVeryDissatisfiedIcon color="error" />,
      label: labels[0],
    },
    2: {
      icon: <SentimentDissatisfiedIcon color="error" />,
      label: labels[1],
    },
    3: {
      icon: <SentimentSatisfiedIcon color="warning" />,
      label: labels[2],
    },
    4: {
      icon: <SentimentSatisfiedAltIcon color="success" />,
      label: labels[3],
    },
    5: {
      icon: <SentimentVerySatisfiedIcon color="success" />,
      label: labels[4],
    },
  };

  const handleChange = (event: any) => {
    setItemSelected(`( ${event.target.value} )`);
    updateFeedbackForm(
      {
        ...feedback,
        question: `Feedback on Menu Item_${event.target.value} )`,
      },
      index
    );
  };

  return (
    <div>
      <Typography variant="h6" sx={{ marginTop: "1rem", fontWeight: "bold" }}>
        {question} {itemSelected}
      </Typography>
      {question.startsWith("Feedback") && (
        <FormControl sx={{ m: 1, minWidth: 200 }}>
          <InputLabel htmlFor="grouped-native-select">Select Item</InputLabel>
          <Select
            onChange={handleChange}
            native
            defaultValue=""
            label="Select Item"
          >
            <option aria-label="None" value="" />
            {menu.map((list: any) => {
              return (
                list.items.length > 0 && (
                  <optgroup label={list.name} key={list.name}>
                    {list.items.map((item: any) => {
                      return (
                        <option value={item.name} key={item.name}>
                          {item.name}
                        </option>
                      );
                    })}
                  </optgroup>
                )
              );
            })}
          </Select>
        </FormControl>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          alignItems: "center",
          justifyContent: isMobile ? "center" : "flex-start",
        }}
      >
        {!question.includes("suggestions") && (
          <StyledRating
            value={value}
            getLabelText={(value: number) => customIcons[value].label}
            highlightSelectedOnly
            IconContainerComponent={IconContainer}
            onChange={(event, newValue) => {
              // setFeedback((prevValue: any) => ({ ...feedback, customerRating: newValue || prevValue }))
              setValue((prevValue) => newValue || prevValue);
              updateFeedbackForm(
                { ...feedback, customerRating: newValue || value },
                index
              );
            }}
            emptyIcon={
              <SentimentVerySatisfiedIcon
                style={{ opacity: 0.55 }}
                fontSize="inherit"
              />
            }
          />
        )}
        {value !== null && !question.includes("suggestion") && (
          <Typography sx={{ ml: 2, display: "flex" }}>
            {customIcons[value || 5].label}
          </Typography>
        )}
      </Box>
      {!question.includes("suggestion") && (
        <>
          <Typography variant="body1" sx={{ marginTop: "0.5rem" }}>
            Would you like to add a comment?
          </Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>No</Typography>
            <Switch
              checked={checked}
              onChange={checkedHandler}
              inputProps={{ "aria-label": "controlled" }}
            />
            <Typography>Yes</Typography>
          </Stack>
        </>
      )}

      {(checked || question.includes("suggestion")) && (
        <div>
          <Typography variant="body1" sx={{ marginTop: "0.5rem" }}>
            What can we do to make the experience better?
          </Typography>
          <TextField
            multiline
            fullWidth
            onChange={(event) =>
              updateFeedbackForm(
                { ...feedback, customerComment: event.target.value },
                index
              )
            }
          />
        </div>
      )}
      <Divider variant="middle" sx={{ marginTop: "1.5rem" }} />
    </div>
  );
}

export default FeedbackSection;
